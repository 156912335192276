<template>
  <v-app id="inspire">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Login </v-toolbar-title>
            </v-toolbar>
            <v-row align="center" justify="center">
              <v-img
                contain
                src="@/assets/Logo NCI 3.png"
                height="80"
                width="170"
                style="margin-top: 20px"
              />
            </v-row>
            <v-card-text>
              <v-form>
                <v-text-field
                  prepend-icon="mdi-account"
                  name="login"
                  label="Login"
                  type="text"
                  v-model="userName"
                ></v-text-field>
                <v-text-field
                  id="password"
                  prepend-icon="mdi-lock"
                  name="password"
                  label="Password"
                  v-model="password"
                  :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPwd ? 'text' : 'password'"
                  @click:append="showPwd = !showPwd"
                ></v-text-field>
                <v-btn
                  class="ma-1"
                  color="purple darken-1"
                  text
                  @click="onRecuperaPwd"
                >
                  Recupera password
                </v-btn>
                <!-- DIALOG Recupera Password -->
                <v-dialog v-model="dialogRecuperaPassword" max-width="500px" width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Recupero Password</v-card-title
                  >
                    <v-col>
                      <v-text-field
                        v-model="mailRecuperoPwd"
                        :label="$t('mailRecupero')">
                      </v-text-field>
                    </v-col>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeRecuperaPassword"
                      >Annulla</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmRecuperaPassword"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>

                  <v-col cols="11">
                    <v-alert
                      id="alertDisattiva"
                      shaped
                      :value="isAlertRecuperaPwd"
                      :type="alertType"
                      dismissible
                    >
                      {{ messaggioAlert }}
                    </v-alert>
                  </v-col>
                </v-card>
              </v-dialog>

                <!--
                <v-select :items="t_profili" label="Profilo"> </v-select>
                <v-select :items="t_enti" label="Ente"> </v-select>
                -->
              </v-form>
            </v-card-text>

            <v-alert
              id="alertGrid"
              shaped
              :value="isAlertVisible"
              :type="alertType"
              dismissible
            >
              {{ messaggioAlert }}
            </v-alert>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary"
                :disabled="!this.userName || !this.password"
               @click="onLoginClick">Login</v-btn>
            </v-card-actions>
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              color="deep-purple accent-4"
            ></v-progress-linear>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import { environment } from "@/environment/environment";
import Utils from "@/utils/Utils";
import AuthService from "@/service/AuthService";
import UtentiService from "@/service/UtentiService";7


@Component
export default class Login extends Vue {
  // Variabili
  userName = "";
  password = "";
  isAlertVisible = false;
  isAlertRecuperaPwd = false;
  showPwd= false;
  messaggioAlert = "";
  alertType = "error";
  vm = this;
  loading = false;
  dialogRecuperaPassword = false;
  mailRecuperoPwd = "";

  created(){
  }

  /*
  t_profili = ["Amministratore", "Manutentore"];

  t_enti = [
    "Nuovo Circondario Imolese",
    "Comune di Imola",
    "Comune di Mordano",
    "Comune di Castel del Rio",
    "Comune di Castel Guelfo di Bologna",
  ];
*/
  onLoginClick() {
    var self = this;
    if(Utils.isNullOrUndefined(this.userName)
        || Utils.isNullOrUndefined(this.password)){
      return 
    }
    this.loading = true;
    AuthService.login(this.userName, this.password, 
      (resp) => {
        //console.log("ciccio");
        //self.vm.$userLogged = resp;
        self.$store.commit('SET_USER', resp);
        // Prelevo le info del JWT per prelevare la profilazione utente
        var jwtDecoded = AuthService.decodeToken();
        let rolesObj = JSON.parse(jwtDecoded.roles);
        //let permissionsObj = JSON.parse(jwtDecoded.permissions);
        self.$store.commit('SET_ROLES', rolesObj);
        //self.$store.commit('SET_PERMISSIONS', permissionsObj);
        if (rolesObj.length == 1)
        {
          self.$store.commit('SET_ROLE_SELECTED', rolesObj[0]);
          self.$store.commit('SET_PERMISSIONS', rolesObj[0].UserPermissions);
        }
        this.loading = false;
      }, 
      (err) => {
        this.loading = false;
        this.alertType = "error";
        if (Utils.isNullOrUndefined(err.response)) 
          this.messaggioAlert = err;
        else 
            if (Utils.isNullOrUndefined(err.response.data)) 
              this.messaggioAlert = err.response;
            else
              if (Utils.isNullOrUndefined(err.response.data.message)) 
                this.messaggioAlert = err.response.data;
              else
                this.messaggioAlert = err.response.data.message;
        this.showHide_alert(5000);
        console.log(err);
    });

  }

  onRecuperaPwd() {
    this.dialogRecuperaPassword = true;
  }

  closeRecuperaPassword() {
    this.dialogRecuperaPassword = false;
  }

  confirmRecuperaPassword() {
    this.recuperaPwdDto();
    this.dialogRecuperaPassword = false;
  }
  
  showHide_alert(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertVisible = false;
      console.log(
        "hide alert after " + millisecondInterval / 1000 + " seconds"
      );
    }, millisecondInterval);
  }

  /// ***********************************
  /// DATABASE
  /// ***********************************

  recuperaPwdDto() {
    var self = this;
    UtentiService.recuperaPassword(
      self.mailRecuperoPwd,
      (resp) => {
        console.log("resetPassword");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alert(5000);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alert(5000);
        }

        this.isHidden = !this.isHidden;
        this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alert(5000);
      }
    );
  }

}
</script>

<style></style>
